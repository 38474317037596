// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
import "expose-loader?exposes=$,jQuery!jquery"

require("@rails/ujs").start()
require("bootstrap")
require('geocomplete')
require('blueimp-file-upload')


// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)
require.context("../images", true)

require("../stylesheets/application.scss")

//= require jquery-fileupload/basic
//= require jquery-fileupload/vendor/tmpl

require('../partials/images')
require('../partials/site_images')
require('../partials/kruger')
require('../partials/table')
require('../partials/camera_button')
require('../partials/search')
require('../partials/assignment_leads')

// require("jquery")

// // include jQuery in global and window scope (so you can access it globally)
// // in your web browser, when you type $('.div'), it is actually refering to global.$('.div')
// import $ from 'jquery';
// global.$ = global.jQuery = jQuery;
// window.$ = window.jQuery = jQuery;

require('../vendor/jquery-ui.min')
require('../vendor/jquery-ui-style.min.css')
